<template>
  <div class="header">
    <div id="nav-menu" class="nav-container">
      <div class="topnav" id="myTopnav">
        <a
          href="#"
          id="menu-title"
          @click="myFunction"
          class="hideMenu active"
        ></a>
        <a href="/" @click="myFunction" class="active b-right">HOME</a>
        <a href="#photo-gallery" @click="myFunction" class="b-right" v-smooth-scroll>GALLERY</a>
        <a href="#dimore" @click="myFunction" class="b-right" v-smooth-scroll>{{ $t("nav.dimore") }}</a>
        <a href="#territorio" @click="myFunction" class="b-right" v-smooth-scroll>{{
          $t("nav.territorio")
        }}</a>
        <a href="#contatti" class="b-right" @click="myFunction" v-smooth-scroll>{{ $t("nav.contatti") }}</a>
        <div class="lang-container" name="" id="lang">
          <a class="lang-select-1" href="" @click="handleChangeEn()">EN</a>
          <a class="slashbar">/</a>
          <a class="lang-select-2" href="" @click="handleChangeIt()">IT</a>
        </div>
        <a href="javascript:void(0);" class="icon" @click="myFunction">
          <i id="icon-menu" class="fa fa-bars"></i>
        </a>
      </div>
    </div>
    <div
      style="margin-top: -1px"
      class="main-container d-flex justify-content-center align-items-center"
    >
      <img src="/assets/img/logo.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    const lang = localStorage.getItem("lang");
    return {
      langBool: false,
      lang: lang,
    };
  },

  methods: {

    myFunction: function() {
      var menuTitle = document.getElementById("menu-title");
      if (
        menuTitle.style.display === "none" &&
        window.matchMedia("(max-width: 992px)")
      ) {
        menuTitle.style.display = "block";
      } else if (window.matchMedia("(min-width: 993px)")) {
        menuTitle.style.display = "none";
      }

      var icon = document.getElementById("icon-menu");
      icon.classList.toggle("fa-times");

      var y = document.getElementById("nav-menu");
      if (y.className === "nav-container") {
        y.className += " fullheight";
      } else {
        y.className = "nav-container";
      }
      var x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
    handleChangeEn() {
      localStorage.setItem("lang", "en");
      window.location.reload();
    },
    handleChangeIt() {
      localStorage.setItem("lang", "it");
      window.location.reload();
    },
  },
};
</script>
