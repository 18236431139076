<template>
  <div id="dimore" class="hideDesktop">
    <div class="room-container-mobile">
      <div id="room1" class="mb-5" style="position: relative;">
        <div class="title-room">
          <h2 id="1pt">SETTE CAPPELLI</h2>
          <img src="/assets/img/iconRoom.svg" alt="icon 7 cappelli" />
        </div>
        <div class="text-start showMore" @click="showDescription()">
          {{ $t("mobile.more") }}
        </div>
        <!-- CAROUSEL -->
        <div class="img-container" @click="openGalleryMobile1()">
          <img class="contain" src="/assets/img/gallery-m1.jpeg" />
        </div>
        <div class="overlay-gallery" id="overlayMobile1">
          <div class="gallery" id="gallery">
            <div class="slider-container">
              <div
                id="carouselMobileFirst"
                class="carousel carousel-dark slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="/assets/img/pt1/1-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/2-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/3-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/4-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/5-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/6-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/7-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/8-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/9-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/10-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/11-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/12-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/13-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt1/14-min.jpg" />
                  </div>
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselMobileFirst"
                  data-bs-slide="prev"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselMobileFirst"
                  data-bs-slide="next"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="btn-close-container">
                  <div class="btn-x" @click="openGalleryMobile1()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END CAROUSEL -->

        <!-- DESCRIPTION -->
        <div id="first-info" class="container-description slide-in-bottom">
          <div class="close-btn" @click="showDescription()">
            <i class="fas fa-times"></i>
          </div>
          <div class="description-content">
            <div class="mt-5 mb-5">
              <div class="descriptionP" v-html="$t('mobile.room1Descrizione')"></div>
              <div
                class="mt-5 mb-3 text-start rules"
                href=""
                @click="toggle = !toggle"
              >
                {{ $t("mobile.read1") }} <br />{{ $t("mobile.read2") }}
              </div>
              <div v-show="toggle">
                <Rules />
              </div>
            </div>
            <div class="services-container">
              <div class="banner-text">
                <h3 class="text-start">{{ $t("homepage.servizi") }}</h3>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-start">
                  <div class="me-2">
                    <ul>
                      <li>{{ $t("homepage.tipsRoom1") }}</li>
                      <li>{{ $t("homepage.maxRoom1") }}</li>
                      <li>{{ $t("homepage.bed") }}</li>
                      <li>{{ $t("homepage.bath") }}</li>
                      <li>{{ $t("homepage.sizeRoom1") }}</li>
                      <li>{{ $t("homepage.noSmoke") }}</li>
                      <li>{{ $t("homepage.frigobar") }}</li>
                      <li class="mt-5" style="text-decoration: underline;"></li>
                    </ul>
                  </div>
                  <div class="column-right ms-2">
                    <ul>
                      <li>Smart TV</li>
                      <li>{{ $t("homepage.wifi") }}</li>
                      <li>{{ $t("homepage.aria") }}</li>
                      <li>{{ $t("homepage.cassaforte") }}</li>
                      <li>{{ $t("homepage.phon") }}</li>
                      <li>{{ $t("homepage.kit") }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END DESCRIPTION -->
        <div>
          <a
            href="https://www.casevacanzepomelia.it/sette-cappelli-237"
            target="_blanK"
          >
            <div class="myBtn2">
              {{ $t("mobile.prenota") }} <br />
              {{ $t("mobile.soggiorno") }}
            </div>
          </a>
        </div>
      </div>

      <div id="room2" class="mt-5" style="position: relative;">
        <div class="title-room">
          <h2 id="pt">CU VIVA</h2>
          <img src="/assets/img/cuviva-icon.svg" alt="icon Cu viva" />
        </div>
        <div class="text-start showMore" @click="showDescription2()">
          {{ $t("mobile.more") }}
        </div>
        <!-- CAROUSEL -->
        <div class="img-container" @click="openGalleryMobile2()">
          <img class="contain" src="/assets/img/gallery-m2.jpeg" />
        </div>
        <div class="overlay-gallery" id="overlayMobile2">
          <div class="gallery" id="gallery">
            <div class="slider-container">
              <div
                id="carouselSecondMobile"
                class="carousel carousel-dark slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active ">
                    <img src="/assets/img/pt/1-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/2-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/3-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/4-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/5-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/6-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/7-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/8-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/9-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/10-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/11-min.jpg" />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselSecondMobile"
                  data-bs-slide="prev"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselSecondMobile"
                  data-bs-slide="next"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="btn-close-container">
                  <div class="btn-x" @click="openGalleryMobile2()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END CAROUSEL -->

        <!-- DESCRIPTION -->
        <div id="second-info" class="container-description slide-in-bottom">
          <div class="close-btn" @click="showDescription2()">
            <i class="fas fa-times"></i>
          </div>
          <div class="description-content">
            <div class="mt-5 mb-5">
              <div class="descriptionP" v-html="$t('mobile.room2Descrizione')"></div>
              <div
                class="mt-5 mb-3 text-start rules"
                href=""
                @click="toggle2 = !toggle2"
              >
                {{ $t("mobile.read1") }}<br />{{ $t("mobile.read2") }}
              </div>
              <div v-show="toggle2">
                <Rules />
              </div>
            </div>
            <div class="services-container">
              <div class="banner-text">
                <h3 class="text-start">SERVIZI</h3>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-start">
                  <div class="me-2">
                    <ul>
                      <li>{{ $t("homepage.tipsRoom1") }}</li>
                      <li>{{ $t("homepage.maxRoom1") }}</li>
                      <li>{{ $t("homepage.bed") }}</li>
                      <li>{{ $t("homepage.bath") }}</li>
                      <li>{{ $t("homepage.sizeRoom2") }}</li>
                      <li>{{ $t("homepage.noSmoke") }}</li>
                      <li>{{ $t("homepage.frigobar") }}</li>
                      <li class="mt-5" style="text-decoration: underline;"></li>
                    </ul>
                  </div>
                  <div class="column-right ms-2">
                    <ul>
                      <li>{{ $t("homepage.wifi") }}</li>
                      <li>{{ $t("homepage.aria") }}</li>
                      <li>Smart TV</li>
                      <li>{{ $t("homepage.cassaforte") }}</li>
                      <li>{{ $t("homepage.phon") }}</li>
                      <li>{{ $t("homepage.kit") }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END DESCRIPTION -->
        <div>
          <a
            href="https://www.casevacanzepomelia.it/cu-viva-235"
            target="_blank"
          >
            <div class="myBtn2">
              {{ $t("mobile.prenota") }} <br />
              {{ $t("mobile.soggiorno") }}
            </div>
          </a>
        </div>
      </div>

      <div id="room3" class="mt-5" style="position: relative;">
        <div class="title-room">
          <h2 id="pt-1">RAFFO</h2>
          <img src="/assets/img/raffo-icon.svg" alt="icon Raffo" />
        </div>
        <div class="text-start showMore" @click="showDescription3()">
          {{ $t("mobile.more") }}
        </div>

        <!-- CAROUSEL -->
        <div class="img-container" @click="openGalleryMobile3()">
          <img class="contain" src="/assets/img/gallery-m3.jpeg" />
        </div>
        <div class="overlay-gallery" id="overlayMobile3">
          <div class="gallery" id="gallery">
            <div class="slider-container">
              <div
                id="carouselThirdMobile"
                class="carousel carousel-dark slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="/assets/img/pt-1/1-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/2-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/3-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/4-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/5-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/6-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/7-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/8-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/9-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/10-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/11-min.jpg" />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselThirdMobile"
                  data-bs-slide="prev"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselThirdMobile"
                  data-bs-slide="next"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="btn-close-container">
                  <div class="btn-x" @click="openGalleryMobile3()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END CAROUSEL -->

        <!-- DESCRIPTION -->
        <div id="third-info" class="container-description slide-in-bottom">
          <div class="close-btn" @click="showDescription3()">
            <i class="fas fa-times"></i>
          </div>
          <div class="description-content">
            <div class="mt-5 mb-5">
              <div class="descriptionP" v-html="$t('mobile.room3Descrizione')">
              </div>
              <div
                class="mt-5 mb-3 text-start rules"
                href=""
                @click="toggle3 = !toggle3"
              >
                {{ $t("mobile.read1") }} <br />{{ $t("mobile.read2") }}
              </div>
              <div v-show="toggle3">
                <Rules />
              </div>
            </div>
            <div class="services-container">
              <div class="banner-text">
                <h3 class="text-start">{{ $t("homepage.servizi") }}</h3>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-start">
                  <div class="me-2">
                    <ul>
                      <li>{{ $t("homepage.tipsRoom1") }}</li>
                      <li>{{ $t("homepage.maxRoom1") }}</li>
                      <li>{{ $t("homepage.bed") }}</li>
                      <li>{{ $t("homepage.bath") }}</li>
                      <li>{{ $t("homepage.sizeRoom3") }}</li>
                      <li>{{ $t("homepage.noSmoke") }}</li>
                      <li class="mt-5" style="text-decoration: underline;"></li>
                    </ul>
                  </div>
                  <div class="column-right ms-2">
                    <ul>
                      <li>{{ $t("homepage.cucina") }}</li>
                      <li>{{ $t("homepage.wifi") }}</li>
                      <li>{{ $t("homepage.aria") }}</li>
                      <li>Smart TV</li>
                      <li>{{ $t("homepage.phon") }}</li>
                      <li>{{ $t("homepage.kit") }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END DESCRIPTION -->
        <div>
          <a href="https://www.casevacanzepomelia.it/raffo-236" target="_blank">
            <div class="myBtn2">
              {{ $t("mobile.prenota") }}<br />
              {{ $t("mobile.soggiorno") }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rules from "@/components/Rules.vue";
export default {
  name: "Home",
  components: {
    Rules,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
      toggle3: false,
    };
  },
  methods: {
    openGalleryMobile1() {
      var overlay = document.getElementById("overlayMobile1");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
        }
      });
    },
    openGalleryMobile2() {
      var overlay = document.getElementById("overlayMobile2");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
        }
      });
    },
    openGalleryMobile3() {
      var overlay = document.getElementById("overlayMobile3");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
        }
      });
    },
    showDescription() {
      var show = document.getElementById("first-info");
      show.classList.toggle("show");
    },
    showDescription2() {
      var show = document.getElementById("second-info");
      show.classList.toggle("show");
      console.log(show);
    },
    showDescription3() {
      var show = document.getElementById("third-info");
      show.classList.toggle("show");
    },
  },
};
</script>

<style scoped>
.img-container {
  height: 56vh;
  margin: 10vh 30px;
  padding: 20px;
  border: 1px solid white;
}
.contain {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.room-container-mobile {
  padding: 40px 20px;
  background-color: #b26b62;
}
.title-room {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-room img {
  height: 30px;
}
.title-room h2 {
  font-family: "Butler";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #ffffff;
}
.showMore {
  font-family: "Intro";
  font-style: normal;
  font-weight: bold;
  font-size: 8.5px;
  line-height: 8px;
  letter-spacing: 0.08em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left !important;
  margin-top: 20px;
  margin-bottom: 10vh;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px 40px;
  color: white;
}
.close-btn i {
  font-size: 20px;
}

.container-description {
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #b26b62;
  z-index: 999;
}
.description-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}

.container-description .descriptionP {
  font-family: "Butler";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.08em;
  color: #ffffff;
  text-align: left;
}
.services-container ul li {
  font-size: 16px;
}

.services-container .column-right {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Butler";
  font-size: 16px;
  color: white;
}

.services-container h3 {
  font-family: "Butler";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
}

.services-container .banner-text {
  padding: 20px 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.container-description .rules {
  font-family: "Intro";
  font-weight: bold;
  font-size: 8.5px;
  line-height: 12px;
  letter-spacing: 0.3em;
  text-decoration: underline;
  color: #ffffff;
}

.rulesBox h3 {
  font-size: 17px;
  margin: 10px 0;
}
.rulesBox p {
  color: white;
}

.myBtn2 * {
  margin: 0;
  box-sizing: border-box;
}

.myBtn2 {
  display: inline-block;
  border: 2px solid white;
  border-radius: 500px;
  padding: 15px 20px;
  margin: 10px 0 50px 0;
}

a .myBtn2,
a .myBtn {
  font-family: "Intro";
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.3em;
  color: #ffffff;
  text-decoration: none;
}
</style>
