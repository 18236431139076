<template>
  <div class="home">
    <Header />

    <!-- CAROUSEL -->
    <div id="photo-gallery" style="position: relative;">
      <div class="firstImage" style="position: relative"></div>
      <div class="gallery-btn" @click="openGallery4()">
        <i class="fas fa-eye"></i>
      </div>
      <div class="overlay-gallery" id="overlay4">
        <div class="btn-close-container">
          <div class="btn-x" @click="openGallery4()">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="gallery" id="gallery">
          <div class="slider-container">
            <div
              id="carouselIbla"
              class="carousel carousel-dark slide"
              data-bs-ride="carousel"
              data-bs-interval="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="/assets/img/photos/1-min.jpg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/2-min.jpg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/3-min.jpeg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/4-min.jpeg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/5-min.jpg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/6-min.jpg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/7-min.jpg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/8-min.jpg" />
                </div>
                <div class="carousel-item">
                  <img src="/assets/img/photos/9-min.jpg" />
                </div>
              </div>

              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselIbla"
                data-bs-slide="prev"
              >
                <div class="container-button">
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                </div>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselIbla"
                data-bs-slide="next"
              >
                <div class="container-button">
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                </div>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END CAROUSEL -->

    <div id="about" class="banner-filtered">
      <div class="text-center contentOverlay">
        <div class="textOverlay">
          {{ $t("homepage.about") }}
        </div>
      </div>
      <div class="overlay"></div>
    </div>

    <!-- MAIN TEXT -->
    <div>
      <div class="p-img">
        <div id="dimore" class="mt-5" style="position: relative;">
          <div style="position: relative; padding: 20px 0">
            <img class="hideMobile houses" src="/assets/img/houses.png" />
            <a href="#cappelli" v-smooth-scroll="{ offset: -80 }"
              ><div class="first-btn hideMobile"></div
            ></a>
            <a href="#cuviva" v-smooth-scroll
              ><div class="second-btn hideMobile"></div
            ></a>
            <a href="#raffo" v-smooth-scroll
              ><div class="third-btn hideMobile"></div
            ></a>
          </div>
          <img
            src="/assets/img/m-house.png"
            alt="Casa Rosa"
            class="houses hideDesktop"
          />
          <div class="textHouses">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-10">
                <p v-html="$t('homepage.mainP1')"></p>

                <div v-show="toggleMainText">
                  <div class="scale-in-ver-top">
                    <p v-html="$t('homepage.mainP2')"></p>
                    <p>
                      {{ $t("homepage.mainP3") }}
                    </p>
                  </div>
                </div>
                <div class="mb-5" style="text-decoration: underline;">
                  <div
                    @click="toggleMainText = !toggleMainText"
                    style="cursor: pointer"
                  >
                    <span
                      v-if="!toggleMainText"
                      style="text-transform: uppercase;"
                    >
                      {{ $t("homepage.showMore") }}
                    </span>
                    <span
                      v-if="(toggleMainText = toggleMainText)"
                      style="text-transform: uppercase;"
                    >
                      {{ $t("homepage.showLess") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- MOBILE -->
            <div class="hideDesktop text-center">
              <div class="arrow mt-4">
                <img src="/assets/img/arrow.svg" alt="" />
              </div>
              <div class="mobile-rooms">
                <div class="item">
                  <div><img src="/assets/img/vec1.png" alt="" /></div>

                  <a href="#room1" v-smooth-scroll>{{
                    $t("homepage.room1")
                  }}</a>
                </div>
                <div class="item">
                  <div><img src="/assets/img/vec2.png" alt="" /></div>
                  <a href="#room2" v-smooth-scroll>{{
                    $t("homepage.room2")
                  }}</a>
                </div>
                <div class="item">
                  <div><img src="/assets/img/vec3.png" alt="" /></div>
                  <a href="#room3" v-smooth-scroll>{{
                    $t("homepage.room3")
                  }}</a>
                </div>
              </div>
            </div>
            <!-- END MOBILE -->
          </div>
        </div>
      </div>
    </div>

    <!-- END MAIN TEXT -->

    <!-- ROOMS -->
    <div class="hideMobile pink-bg pb-5">
      <!-- 7 CAPPELLI -->
      <div class="mb-5" id="cappelli" style="position: relative;">
        <div class="overlay-gallery" id="overlay">
          <div class="gallery" id="gallery">
            <div class="slider-container">
              <div
                id="carouselFirst"
                class="carousel carousel-dark slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="/assets/img/pt1/1-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/2-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/3-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/4-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/5-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/6-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/7-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/8-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/9-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/10-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/12-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/13-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt1/14-min.jpg" />
                  </div>
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselFirst"
                  data-bs-slide="prev"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselFirst"
                  data-bs-slide="next"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="btn-close-container">
                  <div class="btn-x" @click="openGallery()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="secondRoom">
          <div class="title-container d-flex align-items-baseline">
            <h2 class="ml-25">{{ $t("homepage.room1") }}</h2>
            <img class="ms-3 house-icon" src="/assets/img/iconRoom.svg" />
          </div>
          <div class="myBox">
            <img src="/assets/img/firstRoom.png" class="containerRoom1" />
            <a href="#firstRoom1" v-smooth-scroll>
              <div class="insideText">
                <!-- DESCRIZIONE<br /> -->
                <i class="fas fa-arrow-down"></i>
              </div>
            </a>
            <div class="insideButton" @click="openGallery()"></div>
            <a
              id="firstRoom"
              class="bottomLink"
              :href="$t('homepage.book1')"
              target="_blank"
              >{{ $t("homepage.prenota") }}</a
            >
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-md-9 col-lg-9 offset-md-3 offset-lg-3">
            <div
              id="firstRoom1"
              class="text-start descriptionText"
              v-html="$t('homepage.room1Desc')"
            ></div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg-6 col-xl-4 offset-md-3 offset-lg-3"
          >
            <h3 class="text-start">{{ $t("homepage.servizi") }}</h3>
            <div id="container">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <ul>
                    <li>{{ $t("homepage.tipsRoom1") }}</li>
                    <li>{{ $t("homepage.maxRoom1") }}</li>
                    <li>{{ $t("homepage.bed") }}</li>
                    <li>{{ $t("homepage.bath") }}</li>
                    <li>{{ $t("homepage.sizeRoom1") }}</li>
                    <li>{{ $t("homepage.noSmoke") }}</li>
                    <li>{{ $t("homepage.frigobar") }}</li>

                    <li class="mt-5" style="text-decoration: underline;">
                      <a
                        class="rules"
                        href="#rules1"
                        v-smooth-scroll="{ offset: -200 }"
                        @click="toggle = !toggle"
                      >
                        {{ $t("homepage.rules") }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <ul>
                    <li>Smart TV</li>

                    <li>{{ $t("homepage.wifi") }}</li>
                    <li>{{ $t("homepage.aria") }}</li>
                    <li>{{ $t("homepage.cassaforte") }}</li>
                    <li>{{ $t("homepage.phon") }}</li>
                    <li>{{ $t("homepage.kit") }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-9 col-lg-9 col-xl-7 offset-md-3 offset-lg-3">
            <div v-show="toggle">
              <Rules id="rules1" />
            </div>
          </div>
        </div>
      </div>
      <!-- END 7 CAPPELLI -->

      <!-- CU VIVA -->
      <div class="mb-5" id="cuviva" style="position: relative;">
        <div class="overlay-gallery" id="overlay2">
          <div class="gallery" id="gallery">
            <div class="slider-container">
              <div
                id="carouselSecond"
                class="carousel carousel-dark slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active ">
                    <img src="/assets/img/pt/1-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/2-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/3-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/4-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/5-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/6-min.jpeg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/7-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/8-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/9-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/10-min.jpg" />
                  </div>
                  <div class="carousel-item ">
                    <img src="/assets/img/pt/11-min.jpg" />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselSecond"
                  data-bs-slide="prev"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselSecond"
                  data-bs-slide="next"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="btn-close-container">
                  <div class="btn-x" @click="openGallery2()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="secondRoom">
          <div class="title-container d-flex align-items-baseline">
            <h2 class="ml-25">{{ $t("homepage.room2") }}</h2>
            <img class="ms-3 house-icon" src="/assets/img/cuviva-icon.svg" />
          </div>
          <div class="myBox">
            <img src="/assets/img/secondRoom.png" class="containerRoom1" />
            <a href="#secondRoom2" v-smooth-scroll>
              <div class="insideText2">
                <!-- DESCRIZIONE<br /> -->
                <i class="fas fa-arrow-down"></i>
              </div>
            </a>
            <div class="insideButton2" @click="openGallery2()"></div>
            <a
              id="secondRoom"
              class="bottomLink2"
              :href="$t('homepage.book2')"
              target="_blank"
              >{{ $t("homepage.prenota") }}</a
            >
          </div>
          <div class="mt-5 col-12 col-md-9 col-lg-9 offset-md-3 offset-lg-3">
            <div
              id="secondRoom2"
              class="descriptionText"
              v-html="$t('homepage.room2Desc')"
            ></div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg-6 col-xl-4 offset-md-3 offset-lg-3"
          >
            <h3 class="text-start">{{ $t("homepage.servizi") }}</h3>
            <div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <ul>
                    <li>{{ $t("homepage.tipsRoom1") }}</li>
                    <li>{{ $t("homepage.maxRoom1") }}</li>
                    <li>{{ $t("homepage.bed") }}</li>
                    <li>{{ $t("homepage.bath") }}</li>
                    <li>{{ $t("homepage.sizeRoom2") }}</li>
                    <li>{{ $t("homepage.noSmoke") }}</li>
                    <li>{{ $t("homepage.frigobar") }}</li>

                    <li class="mt-5" style="text-decoration: underline;">
                      <a
                        class="rules"
                        href="#rules2"
                        v-smooth-scroll="{ offset: -200 }"
                        @click="toggle2 = !toggle2"
                      >
                        {{ $t("homepage.rules") }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <ul>
                    <li>{{ $t("homepage.wifi") }}</li>
                    <li>{{ $t("homepage.aria") }}</li>
                    <li>Smart TV</li>
                    <li>{{ $t("homepage.cassaforte") }}</li>
                    <li>{{ $t("homepage.phon") }}</li>
                    <li>{{ $t("homepage.kit") }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-9 col-lg-9 col-xl-7 offset-md-3 offset-lg-3"
          >
            <div v-show="toggle2">
              <Rules id="rules2" />
            </div>
          </div>
        </div>
      </div>
      <!-- END CU VIVA -->

      <!-- RAFFO -->
      <div class="mb-5" id="raffo" style="position: relative;">
        <div class="overlay-gallery" id="overlay3">
          <div class="gallery" id="gallery">
            <div class="slider-container">
              <div
                id="carouselThird"
                class="carousel carousel-dark slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="/assets/img/pt-1/1-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/2-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/3-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/4-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/5-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/6-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/7-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/8-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/9-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/10-min.jpg" />
                  </div>
                  <div class="carousel-item">
                    <img src="/assets/img/pt-1/11-min.jpg" />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselThird"
                  data-bs-slide="prev"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselThird"
                  data-bs-slide="next"
                >
                  <div class="container-button">
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="btn-close-container">
                  <div class="btn-x" @click="openGallery3()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="secondRoom">
          <div class="title-container d-flex align-items-baseline">
            <h2 class="ml-25">{{ $t("homepage.room3") }}</h2>
            <img class="ms-3 house-icon" src="/assets/img/raffo-icon.svg" />
          </div>
          <div class="myBox">
            <img src="/assets/img/thirdRoom.png" class="containerRoom1" />
            <a href="#thirdRoom1" v-smooth-scroll>
              <div class="insideText3">
                <!-- DESCRIZIONE<br /> -->
                <i class="fas fa-arrow-down"></i>
              </div>
            </a>
            <div
              id="thirdRoom"
              class="insideButton3"
              @click="openGallery3()"
            ></div>
            <a
              class="bottomLink3"
              :href="$t('homepage.book3')"
              target="_blank"
              >{{ $t("homepage.prenota") }}</a
            >
          </div>
          <div class="mt-5 col-12 col-md-9 col-lg-9 offset-md-3 offset-lg-3">
            <div
              id="thirdRoom1"
              class="descriptionText"
              v-html="$t('homepage.room3Desc')"
            ></div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg-6 col-xl-4 offset-md-3 offset-lg-3"
          >
            <h3 class="text-start">{{ $t("homepage.servizi") }}</h3>
            <div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <ul>
                    <li>{{ $t("homepage.tipsRoom1") }}</li>
                    <li>{{ $t("homepage.maxRoom1") }}</li>
                    <li>{{ $t("homepage.bed") }}</li>
                    <li>{{ $t("homepage.bath") }}</li>
                    <li>{{ $t("homepage.sizeRoom3") }}</li>
                    <li>{{ $t("homepage.noSmoke") }}</li>

                    <li class="mt-5" style="text-decoration: underline;">
                      <a
                        class="rules"
                        href="#rules3"
                        v-smooth-scroll="{ offset: -200 }"
                        @click="toggle3 = !toggle3"
                      >
                        {{ $t("homepage.rules") }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <ul>
                    <li>{{ $t("homepage.cucina") }}</li>
                    <li>{{ $t("homepage.wifi") }}</li>
                    <li>{{ $t("homepage.aria") }}</li>
                    <li>Smart TV</li>
                    <li>{{ $t("homepage.phon") }}</li>
                    <li>{{ $t("homepage.kit") }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-9 col-lg-9 col-xl-7 offset-md-3 offset-lg-3"
          >
            <div v-show="toggle3">
              <Rules id="rules3" />
            </div>
          </div>
        </div>
      </div>
      <!-- END RAFFO -->
    </div>
    <!-- END ROOMS -->

    <!-- ROOMS MOBILE -->
    <RoomsMobile />
    <!-- END ROOMS MOBILE -->

    <div id="territorio" class="map-container">
      <a href="https://goo.gl/maps/kVtGz2TZgrSvfiiLA" target="_blank">
        <img class="map-img" src="/assets/img/map.svg" />
      </a>
    </div>

    <div class="ibla-container"></div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import { Carousel, Slide } from "vue-carousel";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Rules from "@/components/Rules.vue";
import RoomsMobile from "@/components/RoomsMobile.vue";
import $ from "jquery";

export default {
  name: "Home",
  data() {
    return {
      toggleMainText: false,
      toggle: false,
      toggle2: false,
      toggle3: false,
      toggle7Cappelli: false,
      toggleCuviva: false,
      toggleRaffo: false,
    };
  },
  components: {
    // Carousel,
    // Slide,
    Header,
    Footer,
    RoomsMobile,
    Rules,
  },
  mounted() {
    this.smoothScroll();
    $(document).keydown(function(e) {
      if (e.keyCode === 37) {
        // Previous
        $(".carousel-control-prev").click();
        return false;
      }
      if (e.keyCode === 39) {
        // Next
        $(".carousel-control-next").click();
        return false;
      }
    });
  },
  methods: {
    smoothScroll() {
      var body = window.$("html, body");
      body.stop().animate({ scrollTop: 0 }, 500, "swing", function() {});
    },
    scrollOver() {
      document.querySelector("body").style.overflowY = "";
      document.querySelector("html").style.overflowY = "";
    },
    openGallery() {
      var overlay = document.getElementById("overlay");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
          document.querySelector("body").style.overflowY = "";
          document.querySelector("html").style.overflowY = "";
        }
      });
    },
    openGallery2() {
      var overlay = document.getElementById("overlay2");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
          document.querySelector("body").style.overflowY = "";
          document.querySelector("html").style.overflowY = "";
        }
      });
    },
    openGallery3() {
      var overlay = document.getElementById("overlay3");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
          document.querySelector("body").style.overflowY = "";
          document.querySelector("html").style.overflowY = "";
        }
      });
    },
    openGallery4() {
      var overlay = document.getElementById("overlay4");
      overlay.classList.toggle("show");
      if (overlay.classList.contains("show")) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          overlay.classList.remove("show");
          document.querySelector("body").style.overflowY = "";
          document.querySelector("html").style.overflowY = "";
        }
      });
    },
  },
};
</script>
