import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueCarousel from "vue-carousel";
import vueSmoothScroll from "vue2-smooth-scroll";


import "./fonts/stylesheet.css";

const lang = localStorage.getItem("lang") || "it";
document.documentElement.lang = lang;

window.$ = require("jquery");

Vue.config.productionTip = false;
Vue.use(VueCarousel);

Vue.use(vueSmoothScroll, {
  duration: 500, // Animation speed
  offset: -110, // Offset from element, you can use positive or negative values -124offset
  updateHistory: true, //Push hash to history or not
  easeInOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
